import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: {
      show: false,
    },
    order: null,
    user: null,
  },
  mutations: {
    TOGGLE_AUTH_MODAL(state, val) {
      state.auth.show = val;
    },
    SET_USER(state, val) {
      state.user = val;
    },
    SET_ORDER(state, val) {
      state.order = val;
    },
  },
  actions: {
    toggleAuthModal({ commit }, payload) {
      commit("TOGGLE_AUTH_MODAL", payload);
    },
    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    setOrder({ commit }, payload) {
      commit("SET_ORDER", payload);
    },
  },
  getters: {
    showAuthModal: (state) => {
      return state.auth.show;
    },
    user: (state) => {
      return state.user;
    },
    order: (state) => {
      return state.order;
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
