<template>
  <div class="player">
    <audio ref="audio" crossorigin="anonymous" :src="currentTrack"></audio>
    <div class="player__controls">
      <div class="item prev" @click="prevTrack">
        <icon variant="next-track" color="#131757" />
      </div>
      <div class="item" @click="nextTrack">
        <icon variant="next-track" color="#131757" />
      </div>
    </div>
    <div class="player__bg"></div>
    <div class="player__play-pause" @click="playPause">
      <icon
        variant="play"
        v-show="!isPlaying"
        style="margin-left:3px;"
        color="#fff"
      />
      <icon variant="pause" v-show="isPlaying" color="#fff" />
    </div>
    <div class="player__canvas">
      <av-circle
        v-if="!isIOS"
        :outline-width="0"
        :progress-width="3"
        :outline-meter-space="5"
        :playtime="false"
        outline-color="#fff"
        bar-color="#888dff"
        playtime-font="18px Monaco"
        :radius="0"
        progress-color="#ffbd2c"
        :canv-width="60"
        :canv-height="60"
        ref-link="audio"
        :audio-controls="true"
        :cors-anonym="false"
      ></av-circle>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
export default {
  data() {
    return {
      isPlaying: false,
      isIOS: false,
      playlist: [],
      currentTrackIndex: 0,
      currentTrack: null,
    };
  },
  methods: {
    play() {
      var audio = this.$refs.audio;
      audio.addEventListener("canplay", () => {
        audio.play();
        this.isPlaying = true;
      });
    },
    pause() {
      var audio = this.$refs.audio;
      audio.pause();
      this.isPlaying = false;
    },
    playPause() {
      var audio = this.$refs.audio;
      this.isPlaying ? audio.pause() : audio.play();
      this.isPlaying = !this.isPlaying;
    },
    prevTrack() {
      this.pause();
      this.currentTrackIndex--;
      if (this.currentTrackIndex < 0) {
        this.currentTrackIndex = this.playlist.length - 1;
      }
      this.currentTrack = this.playlist[this.currentTrackIndex];

      this.play();
    },
    nextTrack() {
      this.pause();
      this.currentTrackIndex++;
      if (this.currentTrackIndex < this.playlist.length) {
        this.currentTrack = this.playlist[this.currentTrackIndex];
      } else {
        this.currentTrackIndex = 0;
        this.currentTrack = this.playlist[this.currentTrackIndex];
      }

      this.play();
    },
    buildPlaylist() {
        this.isLoading = true;
        this.playlist = []; // Инициализация пустого плейлиста

        firebase.firestore().collection("music")
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    var musicItem = doc.data();
                    if (musicItem.url) {
                        this.playlist.push(musicItem.url); // Добавление URL в плейлист
                    }
                });
                this.isLoading = false;
                if (this.playlist.length > 0) {
                    this.currentTrack = this.playlist[this.currentTrackIndex]; // Установка текущего трека
                    // Вы можете также вызвать this.play() здесь, если хотите начать воспроизведение автоматически
                }
            })
            .catch((error) => {
                console.error("Ошибка при создании плейлиста: ", error);
                this.isLoading = false;
            });
    },
  },
  created() {
    this.buildPlaylist();
  },
  beforeMount() {
    this.isIOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      
  },
};
</script>

<style lang="scss">
.player {
  width: 64px;
  height: 64px;
  position: fixed;
  z-index: 100;
  border-radius: 50%;

  bottom: 100px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .player__controls {
      visibility: visible;
      opacity: 1;
      transition: 0.3s;
    }
  }
  &__bg {
    background: #131757;
    box-shadow: 0px 0px 16px 0px rgba(#000, 0.6);
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__controls {
    position: absolute;
    height: 42px;
    width: 140px;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 12px;
    background: #ffbd2c;
    box-shadow: 0px 0px 16px 0px rgba(#000, 0.4);
    padding-left: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    .item {
      margin: 0px 5px;
      &.prev {
        svg {
          transform: scaleX(-1);
        }
      }
      height: 28px;
      width: 28px;
      min-width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50%;
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
        background: rgba(#131757, 0.1);
      }
      &:active {
        transition: 0.3s;
        background: rgba(#131757, 0.3);
      }
    }
  }
  &__canvas {
    position: relative;
    z-index: 11;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 64px;
        height: 64px;
        border-radius: 50%;
      }
    }
  }
  &__play-pause {
    position: absolute;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 12;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  audio {
    // display: none!important;
  }
}
</style>
