<template>
  <div class="header__mobile-menu" :class="{ opened: open }">
    <div class="fader" @click="emitClose"></div>
    <div class="bg">
      <div class="head d-flex align-items-center justify-content-between">
        <img src="@/assets/img/logo.svg" class="header__logo" alt="" />
        <div @click="emitClose">
          <icon variant="close" color="#fff" />
        </div>
      </div>
      <div class="menu-body">
        <div class="user" v-if="user && user.email">
          <div class="balance d-flex align-items-center" v-if="user.coins">
            <img src="@/assets/img/coin.png" alt="" /> {{ user.coins }}
          </div>
        </div>
        <ul class="menu">
          <li>
            <router-link :to="{ name: 'shop' }">{{ $t("shop") }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'about' }">{{ $t("about") }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'winners' }">{{
              $t("winners")
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["open"],
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    emitClose() {
      this.$emit("closeMenu", true);
    },
  },

};
</script>

<style lang="scss" scoped>
.header {
  &__mobile-menu {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
    position: fixed;
    left: 0;
    width: 100vw;
    top: 0;
    bottom: 0;

    z-index: 100 !important;
    transform: translateX(-100%);
    transition: 0.3s;
    .fader {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      cursor: pointer;
      z-index: 102 !important;
      transition: 0.3s;
    }
    .bg {
      background: #111230;
      width: 300px;
      height: 100vh;
      z-index: 110 !important;
      position: relative;
      transform: translateX(-100%);
      .head {
        height: 64px;
        padding: 0px 15px;
        img {
          height: 32px;
        }
        svg {
          fill: #888dff;
          margin-right: 10px;
          cursor: pointer;
        }
      }
      .menu-body {
        padding: 15px;
        .user {
          .balance {
            color: #fff;
            img {
              margin-right: 8px;
            }
          }
        }
        .menu {
          list-style: none;
          margin: 0;
          padding: 0;
          margin:30px 0px;
          li {
            margin-right: 15px;
            padding: 10px 0px;
            border-bottom: 1px solid rgba(#fff, 0.1);
            a {
              text-decoration: none;
              font-family: Roboto;
              font-style: normal;
              font-weight: 300;
              font-size: 16px;
              color: #ffffff;
              text-transform: capitalize;
              display: block;
              &.router-link-active {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
    &.opened {
      transition: 0.3s;
      transform: translateX(0);
      .fader {
        transition: 0.3s;
        background: rgba(#111230, 0.5);
        transition-delay: 0.3s;
      }
      .bg {
        transition: 0.3s;
        transform: translateX(0);
        box-shadow: 20px 0px 26px 0px rgba(#000, 0.5);
      }
    }
  }
}
</style>
