<template>
  <div
    id="app"
    :style="{
      backgroundImage: 'url(' + require('@/assets/img/app_bg.svg') + ')',
    }"
  >
    <iconsCollection />
    <appHeader />
    <musicPlayer />
    <main>
      <router-view />
    </main>
    <appFooter />
  </div>
</template>
<script>
import iconsCollection from "@/components/icons/icons-collection";
import musicPlayer from '@/components/music-player.vue'
import appHeader from "@/layout/header.vue";
import appFooter from "@/layout/footer.vue";
import firebase from "firebase/app";
export default {
  data() {
    return {};
  },
  components: {
    iconsCollection,
    appHeader,
    appFooter,
    musicPlayer
  },
  methods: {
    async getTranslations() {
      await firebase
        .firestore()
        .collection("settings")
        .doc("translations")
        .get()
        .then((doc) => {
          var messages = doc.data();
          for (var key in messages) {
            this.$i18n.setLocaleMessage(key, messages[key]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkLang() {
      var lang = localStorage.getItem("language");
      if (lang) {
        this.$i18n.locale = lang;
      }
    },
  },
  mounted() {
    this.checkLang();
    this.getTranslations();
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,100&display=swap");
#app {
  min-height: 100vh;
  background-size: cover;
  @media (max-width: 991px) {
    padding-top: 72px;
  }
}
main {
  min-height: 100vh;
}
</style>
