<template>
  <div>
    <b-modal
      id="modal-auth"
      v-model="showAuthModal"
      hide-footer
      hide-header
      centered
      title="BootstrapVue"
    >
      <b-overlay
        :show="isLoading"
        rounded="sm"
        variant="dark"
        bgColor="#16193e"
      >
        <div class="auth__logo text-center py-2 mb-4 pb-4">
          <img src="@/assets/img/logo.svg" alt="" />
        </div>
        <div class="resetPassword" v-if="auth_mode === 'reset'">
          <div class="text-center">
            Please enter your email address. You will receive a link to create new password
          </div>
          <div class="pt-2" v-if="error">
            <b-alert show variant="danger">{{ error }}</b-alert>
          </div>
          <div class="forn-group mb-2">
            <label>Email</label>
            <b-form-input
              type="email"
              v-model="reset_password"
              placeholder="Email"
            ></b-form-input>
          </div>
          <b-button
            variant="primary"
            block
            size="lg"
            class="w-100"
            @click="resetPassword">Reset password
          </b-button>
          <div class="text-center mt-4" style="cursor: pointer;" @click="cancelResetPassword"> 
            Cancel
          </div>
        </div>
        <div v-else>
          <div class="form-group text-center mb-2">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="auth-mode-switch"
                v-model="auth_mode"
                :options="options"
                :aria-describedby="ariaDescribedby"
                button-variant="warning"
                name="auth-mode"
                size="sm"
                buttons
              ></b-form-radio-group>
            </b-form-group>
            <div class="pt-2" v-if="error">
          <b-alert show variant="danger">{{ error }}</b-alert>
        </div>
        <div class="forn-group mb-2">
          <label>Email</label>
          <b-form-input
            type="email"
            v-model="email"
            placeholder="Email"
          ></b-form-input>
        </div>
        <div class="forn-group mb-2">
          <label>Password</label>
          <b-form-input
            type="password"
            v-model="password"
            placeholder="Password"
          ></b-form-input>
        </div>
        <div class="forn-group" v-if="auth_mode == 'register'">
          <label>Password repeat</label>
          <b-form-input
            type="password"
            v-model="password_repeat"
            placeholder="Password repeat"
          ></b-form-input>
        </div>
        <div class="form-group pt-4 w-100">
          <b-button
            v-if="auth_mode == 'login'"
            variant="primary"
            block
            size="lg"
            class="w-100"
            @click="loginWithEmail"
          >
            Log In
          </b-button>
          <b-button
            v-if="auth_mode == 'register'"
            variant="success"
            block
            size="lg"
            class="w-100"
            @click="createAccountWithEmail"
          >
            Create account
          </b-button>
          <b-button
            variant="light-outline"
            block
            size="sm"
            class="w-100 mt-4"
            @click="closeAuthModal"
          >
            Cancel
          </b-button>
          </div>
        </div>
          <div
            variant="light-outline"
            size="sm"
            class="w-100 mt-4 text-center"
            style="cursor: pointer;"
            @click="auth_mode = 'reset'"
            >
              Forgot password?
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
export default {
  data() {
    return {
      isLoading: false,
      error: null,
      email: "",
      password: "",
      reset_password: "",
      password_repeat: "",
      auth_mode: "login",
      options: [
        { text: "Log In", value: "login" },
        { text: "Create account", value: "register" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      showAuthModal: "showAuthModal",
    }),
  },
  methods: {
    resetError() {
      this.error = null;
    },
    closeAuthModal() {
      this.$store.dispatch("toggleAuthModal", false);
    },
    loginWithEmail() {
      this.resetError();
      this.isLoading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((userCredential) => {
          this.isLoading = false;
          // Signed in
          let user = {
            email: userCredential.user.email,
            displayName: userCredential.user.displayName,
            uid: userCredential.user.uid,
            created_at: userCredential.user.metadata.creationTime,
          };
          this.$store.dispatch("setUser", user);
          this.closeAuthModal();
        })
        .catch((error) => {
          this.isLoading = false;
          this.error = error;
        });
    },
    createAccountWithEmail() {
      this.resetError();
      this.isLoading = true;
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(async (userCredential) => {
          // Signed in
          let user = {
            email: userCredential.user.email,
            displayName: userCredential.user.displayName,
            uid: userCredential.user.uid,
            created_at: userCredential.user.metadata.creationTime,
            coins: 0,
          };

          var user_id = await this.getNewUserId();

          user["user_id"] = user_id;
          await this.createUserRecordInFirestore(user);

          this.isLoading = false;
          this.closeAuthModal();
          this.$store.dispatch("setUser", user);
        })
        .catch((error) => {
          this.isLoading = false;
          this.error = error;
        });
    },
    async createUserRecordInFirestore(user) {
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .set(user)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    async getNewUserId() {
      var id = null;
      await this.$api
        .get("/get/counter?type=users")
        .then((response) => {
          console.log(response);
          id = Number(response.data.count);
        })
        .catch((error) => {
          console.log(error);
        });

      return id;
    },
    async resetPassword() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.reset_password)
        .then(() => {
          this.$toast.success("Email sent");
          this.closeAuthModal();
          this.auth_mode = "login";
        })
        .catch((error) => {
          this.error = error;
        });
    },
    cancelResetPassword() {
      this.auth_mode = "login";
      this.error = null;
    },
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId == "modal-auth") {
        this.closeAuthModal();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.auth {
}
</style>
