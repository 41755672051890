import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/app";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/About.vue"),
  },
  {
    path: "/winners",
    name: "winners",
    component: () => import("@/views/Winners.vue"),
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import("@/views/Shop.vue"),
  },
  {
    path: "/shop/product/:id",
    name: "product",
    component: () => import("@/views/Product.vue"),
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("@/views/Checkout.vue"),
  },
  {
    path: "/my/:tab",
    name: "my",
    component: () => import("@/views/My.vue"),
  },
  {
    path: "/pay/result/:orderId",
    name: "payment-result",
    component: () => import("@/views/PaymentResult.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return { selector: to.hash };
      //Or for Vue 3:
      //return {el: to.hash}
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  firebase.auth().onAuthStateChanged((user) => {
    if (requiresAuth && !user) next("login");
    // else if (user && !user.email && to.path == "/login") next('/register');
    // else if (user && user.email && to.path == "/login") next('/monitoring');
    // else if (user && user.email && to.path == "/register") next('/monitoring');
    else next();
  });
});

export default router;
