<template>
  <div>
    <div class="header__language-modal-toggle" @click="showLangModal = true">
      <icon variant="globe" color="#888dff" />
      <span>
        {{ $i18n.locale }}
      </span>
    </div>

    <b-modal
      id="language-switch-modal"
      v-model="showLangModal"
      hide-footer
      hide-header
      centered
    >
      <h4 class="mb-4 pb-4 d-flex align-items-center justify-content-center">
        <icon variant="globe" color="#888dff" />
        <span style="margin-left:14px">{{ $t("language") }}</span>
      </h4>
      <div class="row mb-4 text-center">
        <div class="col-12" v-for="l in activelanguages" :key="l.code">
          <span
            class="lang_item d-block"
            @click="setLang(l.code)"
            :class="{ current: $i18n.locale == l.code }"
          >
            {{ l.label }}
          </span>
        </div>
      </div>
      <b-button
        variant="link"
        block
        size="md"
        class="w-100 mt-4"
        @click="showLangModal = false"
      >
        Cancel
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import firebase from "firebase/app";
export default {
  data() {
    return {
      showLangModal: false,
      isLoading: false,
      languages: [],
      current_lang: "en",
      current_lang_label: "",
    };
  },
  computed: {
    activelanguages() {
      var languages = [];
      if (this.languages) {
        for (var i = 0; i < this.languages.length; i++) {
          if (
            this.languages[i].available == "true" ||
            this.languages[i].available == true
          ) {
            languages.push(this.languages[i]);
          }
        }
      }
      return languages;
    },
  },
  watch: {},
  methods: {
    setLang(lang) {
      this.current_lang = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
      this.$bvModal.hide("language-switch-modal");
    },
    getLanguages() {
      this.isLoading = true;
      firebase
        .firestore()
        .collection("settings")
        .doc("languages")
        .get()
        .then((doc) => {
          this.languages = doc.data().languages;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeMount() {
    this.current_lang = this.$i18n.locale;
  },
  mounted() {
    this.getLanguages();
  },
};
</script>

<style lang="scss" scoped>
.lang_item {
  cursor: pointer;
  color: #888dff;
  padding: 2px 6px 2px 6px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 5px;
  &.current {
    color: #fff;
    text-decoration: none !important;
  }
  &:hover {
    background: rgba(#888dff, 0.2);
    // text-decoration: underline;
  }
}
.header {
  &__language-modal-toggle {
    display: flex;
    align-items: center;
    color: #888dff;
    margin-right: 18px;
    cursor: pointer;
    background: rgba(#888dff, 0.2);
    height: 24px;
    padding: 4px 8px 4px 6px;
    border-radius: 4px;
    svg {
      height: 14px;
      width: 14px;
    }
    span {
      margin-left: 6px;
      line-height: 1;
      text-transform: capitalize;
    }
  }
}
</style>
