const getLocaleProductImage = (product, locale) => {
  var image_url = product.image_url;
  if (
    product.translations &&
    product.translations[locale] &&
    product.translations[locale].image_url
  ) {
    image_url = product.translations[locale].image_url;
  }
  return image_url;
};

const getLocaleProductTitle = (product, locale) => {
  var title = product.title;
  if (
    product.translations &&
    product.translations[locale] &&
    product.translations[locale].title
  ) {
    title = product.translations[locale].title;
  }
  return title;
};

const getLocaleProductDescription = (product, locale) => {
  var description = product.description;
  if (
    product.translations &&
    product.translations[locale] &&
    product.translations[locale].description
  ) {
    description = product.translations[locale].description;
  }
  return description;
};


const getLocaleAboutFulltext = (about, locale) => {
  var full_text = about.full_text;
  if (
    about.translations &&
    about.translations[locale] &&
    about.translations[locale].full_text
  ) {
    full_text = about.translations[locale].full_text;
  }
  return full_text;
};

const getLocaleAboutHometext = (about, locale) => {
  var home_text = about.home_text;
  if (
    about.translations &&
    about.translations[locale] &&
    about.translations[locale].home_text
  ) {
    home_text = about.translations[locale].home_text;
  }
  return home_text;
};

export {
  getLocaleProductImage,
  getLocaleProductTitle,
  getLocaleProductDescription,
  getLocaleAboutFulltext,
  getLocaleAboutHometext
};
