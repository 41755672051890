<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3">
          <router-link :to="{ name: 'home' }">
            <img src="@/assets/img/logo.svg" alt="" />
          </router-link>
        </div>
        <div class="col-12 col-md-3">
          <div class="footer__title">{{$t('information')}}</div>
          <div class="d-flex">
            <ul class="footer__menu">
              <li>
                <router-link :to="{ name: 'shop' }">{{
                  $t("shop")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'about' }">{{
                  $t("about")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'winners' }">{{
                  $t("winners")
                }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="footer__title">{{$t('follow_us')}}</div>
          <div >
            <ul class="footer__menu d-flex">
              <li class="footer__menu-social">
                <a href="https://www.facebook.com/Reward99-104624665668149/?ref=pages_you_manage" target="_blank">
                  <img src="@/assets/img/facebook.svg" alt="" />
                </a>
              </li>
              <li class="footer__menu-social">
                <a href=" https://www.instagram.com/reward99_official/" target="_blank">
                  <img src="@/assets/img/instagram.svg" alt="" />
                </a>
              </li>
              <li class="footer__menu-social">
                <a href="https://www.youtube.com/channel/UCYGzgAedmdtPdFj2CfMNn2Q" target="_blank">
                  <img src="@/assets/img/youtube.svg" alt="" />
                </a>
              </li>
              
            </ul>
            <ul class="footer__menu d-flex">
              <li class="footer__menu-social">
                <a href=" https://www.tiktok.com/@official_reward99" target="_blank">
                  <img src="@/assets/img/tiktok.svg" alt="" />
                </a>
              </li>
              <li class="footer__menu-social">
                <a href="https://twitter.com/Reward_99" target="_blank">
                  <img src="@/assets/img/twitter.svg" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="footer__title">{{$t('contact_info')}}</div>
          <div class="d-flex">
            <ul class="footer__menu">
              <li>
                <a href="mailto: info@reward99.com">
                  info@reward99.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="copyright">
            © {{$t('copyright_footer')}}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  padding-top: 80px;
  .copyright {
    text-align: center;
    padding: 40px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 30px;
  }
  &__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #98c8f3;
    margin-bottom: 20px;
    padding-top: 10px;
  }
  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 160px;
    &-social {
      img {
        max-width: 48px !important;
        width: 100%;

      }
    }
    li {
      padding-bottom: 10px;
      margin-right: 10px;

      a {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        text-decoration: none;
      }
    }
    img {
      max-width: 30px;
      width: 100%;
    }
  }
}
</style>
