import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase";

import axios from "axios";
Vue.use(VueI18n);

import VueI18n from "vue-i18n";

//Bootstrap
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/scss/bootstrap-theme.min.css";
import "@/scss/custom.scss";
Vue.use(BootstrapVue);

//Icons utility
import icon from "@/components/icons/icon-component";
Vue.component("icon", icon);

const firebaseConfig = {
  apiKey: "AIzaSyDeu_oQmhVJq03rdHxX8abZByWpgpt1rIA",
  authDomain: "reward99-app.firebaseapp.com",
  projectId: "reward99-app",
  storageBucket: "reward99-app.appspot.com",
  messagingSenderId: "238467605615",
  appId: "1:238467605615:web:38921c5daa8c75f305d99a",
  measurementId: "G-PME9YCEJE5",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

firebase.auth().onAuthStateChanged(async (userCredential) => {
  if (userCredential) {
    let user = {
      email: userCredential.email,
      displayName: userCredential.displayName,
      uid: userCredential.uid,
      created_at: userCredential.metadata.creationTime,
    };
    await firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .get()
      .then((doc) => {
        user = doc.data();
      });
    store.dispatch("setUser", user);
  }
});
Vue.config.productionTip = false;

import i18n from "@/translations/index.js";

var utils = require("@/plugins/utilities.js");
Vue.prototype.$utils = utils;

const axiosApi = axios.create({
  baseURL: "https://us-central1-reward99-app.cloudfunctions.net/api",
  // baseURL: "http://localhost:5001/reward99-app/us-central1/api",
});
Vue.prototype.$api = axiosApi;

import { ContentLoader } from "vue-content-loader";
Vue.component("ContentLoader", ContentLoader);


import AudioVisual from 'vue-audio-visual'
Vue.use(AudioVisual)

Vue.use(require("vue-moment"));

// vue-toastification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
};
Vue.use(Toast, options);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
