<template>
  <div class="header__user d-flex align-items-center justify-content-end">
    <div class="balance  d-none d-lg-flex align-items-center">
      <img src="@/assets/img/coin.png" alt="" /> {{user.coins || 0}}
    </div>
    <div class="info d-flex align-items-center">
      <div class="avatar-icon" variant="warning">
        {{ avatarLetters }}
      </div>
      <b-dropdown id="dropdown-left" variant="link" right>
        <b-dropdown-item :to="{name:'my', params:{tab:'orders'}}">{{$t('my_orders')}}</b-dropdown-item>
        <b-dropdown-item :to="{name:'my', params:{tab:'profile'}}">{{$t('my_profile')}}</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click.prevent="signOut">{{$t('log_out')}}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
export default {
  props: ["user"],
  computed: {
    avatarLetters() {
      var letters = "AB";
      if (this.user && this.user.email) {
        letters = this.user.email.substring(0, 2);
      }
      return letters;
    },
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(
          () => {
            this.$store.dispatch("setUser", null);
            this.$router.push({ name: "home" });
          },
          (error) => {
            console.error("Sign Out Error", error);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &__user {
    color: #fff;
    .balance {
      margin-right: 22px;
      padding-right: 22px;
      border-right: 1px solid rgba(#e7e7e7, 0.3);
      img {
        height: 18px;
        margin-right: 4px;
      }
    }
    .avatar-icon {
      height: 28px;
      width: 28px;
      min-width: 28px;
      border-radius: 50%;
      color: #000;
      background: #fff;
      display: flex;
      text-transform: uppercase;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      box-sizing: border-box;
      border: 1px solid #fff;
      box-shadow: 0px 0px 8px 0px rgba(#000, 0.3);
      margin-right: 0px;
    }
  }
}
</style>
