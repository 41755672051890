<template>
  <div>
    <header class="header">
      <div
        class="bg"
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/app_bg.svg') + ')',
        }"
      ></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-8 col-lg-3 d-flex align-items-center">
            <div
              class="header__menu-toggle d-lg-none"
              :class="{ opened: menuOpened }"
              @click="menuOpened = !menuOpened"
            >
              <i></i>
              <i></i>
              <i></i>
            </div>
            <router-link to="/">
              <img src="@/assets/img/logo.svg" class="header__logo" alt="" />
            </router-link>
          </div>
          <div class="d-none d-lg-block col-lg-5">
            <ul class="d-flex header__menu">
              <li>
                <router-link :to="{ name: 'shop' }">{{
                  $t("shop")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'about' }">{{
                  $t("about")
                }}</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'winners' }">{{
                  $t("winners")
                }}</router-link>
              </li>
            </ul>
          </div>
          <div
            class="col-4 col-lg-4 d-flex justify-content-end align-items-center"
          >
            <languageSwitcher />
            <template v-if="!user">
              <a
                href="#"
                class="d-flex align-items-center has-icon"
                @click.prevent="showAuthModal"
              >
                <icon :variant="'lock'" color="#fff" /> SIgn In
              </a>
            </template>
            <template v-if="user">
              <headerUser :user="user" />
            </template>
          </div>
        </div>
      </div>
      <authModal />
      <mobileMenu :open="menuOpened" @closeMenu="menuOpened = false" />
    </header>
  </div>
</template>

<script>
import authModal from "@/components/modals/authModal.vue";
import headerUser from "@/layout/header-components/header-user.vue";
import mobileMenu from "@/layout/mobileMenu.vue";
import languageSwitcher from "@/components/language-switcher.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      menuOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    $route: {
      handler() {
        this.menuOpened = false;
      },
    },
  },
  components: {
    authModal,
    headerUser,
    languageSwitcher,
    mobileMenu,
  },
  methods: {
    showAuthModal() {
      this.$store.dispatch("toggleAuthModal", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 72px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  * {
    z-index: 10;
  }
  .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.85;
    background-size: cover;
    backdrop-filter: blur(10px);
    @media (min-width: 992px) {
      display: none;
    }
  }
  @media (max-width: 991px) {
    height: 64px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 300;
  }
  &__menu-toggle {
    position: relative;
    height: 24px;
    z-index: 1001 !important;
    margin-left: 10px;
    cursor: pointer;
        width: 24px;
    margin-right: 20px;
    i {
      height: 2px;
      width: 24px;
      background: #fff;
      display: block;
      position: absolute;
      top: 1px;
      transition: 0.3s;
      &:nth-child(2) {
        top: 11px;
      }
      &:nth-child(3) {
        top: 21px;
      }
    }
    // &.opened {
    //   i {
    //     background: #888dff;
    //     &:nth-child(1) {
    //       transition: 0.3s;
    //       transform: rotate(45deg);
    //       top: 50%;
    //     }
    //     &:nth-child(2) {
    //       transform: translateX(-20px);
    //       opacity: 0;
    //     }
    //     &:nth-child(3) {
    //       transition: 0.3s;
    //       transform: rotate(-45deg);
    //       top: 50%;
    //     }
    //   }
    // }
  }
  &__logo {
    @media (max-width: 991px) {
      height: 32px;
      display: block;
      margin: 0 auto;
      position: relative;
    }
  }
  &__menu {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-right: 15px;
      a {
        text-decoration: none;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        color: #ffffff;
        text-transform: capitalize;
        &.router-link-active {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
